import { usePageData } from 'lib/hooks/PageDataContext';
import axios from 'lib/api/axios';
import { FunnelProvider } from '../../components/funnel/Funnel';

const Index = ({ locale }) => {
  const { owner, token, requestLink, prefill } = usePageData();

  const defaultValue = {
    type: null,
    phone_number: prefill.phone_number,
    customer_locale: locale,
  };

  const handleSubmit = async (data: any) => {
    const { photo, ...fields } = data;

    fields.company_slug_url = owner.company_url_slug;
    fields.token_id = token?.id;
    fields.request_link_id = requestLink?.id;

    try {
      const result = await axios.post('api/request/details', fields);

      if (photo) {
        const photoData = new FormData();
        photoData.append('photo', photo);
        photoData.append('request_id', result.data.id);

        await axios.post('api/request/photo', photoData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const initialStep = {
    name: 'selector',
    next: [],
  }

  return (
    <FunnelProvider initialStep={initialStep}
                    initialData={defaultValue}
                    handleSubmit={handleSubmit}>
    </FunnelProvider>
  );
};

export default Index;
